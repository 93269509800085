




































































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import { User } from '@/types'
import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import { CashBackApi, CreateCashBackCommand } from '@/api'

import { formatDate } from '@/util/formatDate'

interface InputFields {
  iban: InputField;
  subscriptionId: InputField;
  ibanAccountHolder: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  },
  methods: {
    formatDate
  }
})
export default class CashBackAction extends mixins(State) {
  inputFields: InputFields = {
    subscriptionId: {
      name: 'subscriptionId',
      value: this.currentUser.subscription?.subscriptionId?.toString() || '',
      type: 'number',
      inline: false,
      placeholder: 'Abonnementsnummer',
      errors: [],
      required: true,
      readonly: true,
      disabled: true
    },
    iban: {
      name: 'iban',
      value: this.currentUser.employeeDetails?.iban || '',
      type: 'text',
      inline: false,
      placeholder: 'Iban',
      errors: [],
      required: true
    },
    ibanAccountHolder: {
      name: 'ibanAccountHolder',
      value: this.currentUser.employeeDetails?.ibanAccountHolder || '',
      type: 'text',
      inline: false,
      placeholder: 'Naam rekeninghouder',
      errors: [],
      required: true
    }
  }

  hasDirtyInput = false || (!!this.currentUser.subscription?.subscriptionId && !!this.currentUser.employeeDetails?.iban && !!this.currentUser.employeeDetails?.ibanAccountHolder)

  // Show a message immediately after the cashback is requested
  sentEmail = false

  // Show a message when someone is navigation to the cashback page on a later moment
  done = false

  cashbackEndDate = '';

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get subscription () {
    return this.currentUser.subscription
  }

  get isWorkit (): boolean {
    if (this.currentUser.subscriptionDetails?.sportLocationNumber?.startsWith('FC7000')) {
      return true
    }
    return false
  }

  public async loadCashBack () {
    if (this.currentUser.subscription?.subscriptionId) {
      if (this.currentUser.subscriptionDetails?.showCashbackPageInPortal === true) {
        this.cashbackEndDate = this.currentUser.subscriptionDetails?.cashbackEndDate || ''

        const api = new CashBackApi()
        await api.cashBackCashBackRequested(this.currentUser.subscription?.subscriptionId)
          .then(({ data }) => {
            this.done = data
          })
          .catch(() => {
            this.setState(this.states.ERROR)
          })
      } else {
        this.setState(this.states.ERROR)
      }
    }

    this.setState(this.states.LOADED)
  }

  public async createCashBack () {
    this.clearErrors()
    if (!this.validateInput()) {
      return
    }

    const api = new CashBackApi()

    const fields: CreateCashBackCommand = {
      subscriptionId: Number(this.inputFields.subscriptionId.value),
      ibanAccountHolder: this.inputFields.ibanAccountHolder.value,
      iban: this.inputFields.iban.value
    }

    try {
      this.state = this.states.LOADING
      this.clearErrors()
      await api.cashBackCreateCashBack(fields)
      this.hasDirtyInput = false
      this.sentEmail = true

      this.state = this.states.COMPLETE
    } catch (error) {
      this.state = this.states.ERROR

      this.setErrors(error.response.data.errors)

      this.$root.$emit('scrollToTop')
    }
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public clearErrors () {
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      fieldValue.errors = []
    })
  }

  public inputChange () {
    this.hasDirtyInput = true
  }

  private validateInput () {
    let valid = true

    Object.keys(this.inputFields).forEach((key: string) => {
      let input
      if (Array.isArray(this.$refs[key])) {
        input = (this.$refs[key] as Element[])[0]
      } else {
        input = this.$refs[key]
      }
      valid = (input as FormInput).validate() && valid
    })

    return valid
  }

  get hasErrors () {
    let hasError = false
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      hasError = hasError || fieldValue.errors.length > 0
    })
    return hasError
  }

  mounted () {
    this.loadCashBack()
  }
}
